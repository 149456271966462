import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../util/axios/axios";
import useFetch from "../../util/useFetch";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Helmet } from "react-helmet";

const IndividualProcess = () => {
  const { data: individualProcess } = useFetch(`${BASE_URL}equipment`);
  const { data: processDetail } = useFetch(`${BASE_URL}our-service`);
  const { process } = useParams();
  // console.log(processDetail);

  const [mainChoice, setMainChoice] = useState();
  const [selectedChoice, setSelectedChoice] = useState([]);
  // for overlay
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const mainProcessChoice =
      processDetail &&
      processDetail.results.find(
        (indiProcess) => indiProcess.service_category === process
      );

    setMainChoice(mainProcessChoice);

    if (mainProcessChoice) {
      const selectedProcess =
        individualProcess &&
        individualProcess.results.filter(
          (indiProcess) => indiProcess.service === mainProcessChoice.id
        );
      setSelectedChoice(selectedProcess);
    }
  }, [processDetail, process, individualProcess]);

  // console.log(selectedChoice);

  if (!mainChoice) {
    return <div>Process details not found.</div>;
  }

  // for overlay
  if (!selectedChoice || selectedChoice.length === 0) {
    return <div>No machineries found for this process.</div>;
  }

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedChoice.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + selectedChoice.length) % selectedChoice.length
    );
  };

  // Machine categories
  const categories = [
    { link: "printing", name: "Printing" },
    { link: "prePress", name: "Pre Press" },
    { link: "printOnDemand", name: "Print On Demand" },
    { link: "postPress", name: "Post Press" },
  ];
  // const category = "";

  console.log(selectedChoice);

  return (
    <div className="font-poppins bg-[#FEF8F0]">
      <Helmet>
        <title>{mainChoice.name} | Sewa Printing Press</title>
        <meta
          name="description"
          content="Explore Sewa Printing Press for all your printing needs, from advanced sheet-fed offset machines to expert pre-press design, on-demand digital printing, and professional post-press services. Quality and efficiency guaranteed."
        />
        <meta
          name="keywords"
          content="printing solutions, pre-press services, digital printing, post-press services, Sewa Printing Press, print on demand, sheet-fed offset machines"
        />
      </Helmet>
      <div className="max-w-screen-2xl mx-auto md:px-10 px-5 py-20 pt-32">
        <h1 className="max-w-screen-2xl mx-auto font-semibold text-[48px] lg:text-[80px] z-20 ">
          {mainChoice.name}
        </h1>
        <div className="max-w-screen-2xl mx-auto text-[20px] mt-10 flex z-20 gap-4">
          {categories.map((cat, index) => (
            <a
              href={`/services/` + cat.link}
              key={index}
              className={`pb-5 text-left cursor-pointer transition-colors duration-300 ${
                process === cat.link
                  ? "font-semibold rounded-md"
                  : "hover:underline rounded-md"
              }`}
            >
              {cat.name}
            </a>
          ))}
        </div>
        {/* TODO: add nav to other pages */}
        <div className="pb-10">
          {/* <h1 className="font-semibold text-[35px] text-[#000000]">
            {mainChoice.name}
          </h1> */}
          <span className="text-[16px] font-light text-justify">
            {mainChoice.description}
          </span>
        </div>
        <div>
          <h2 className="font-semibold text-[25px] text-[#000000]">
            Machineries
          </h2>
          {process === "printing" && (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden py-6">
                <thead>
                  <tr className="bg-[#176CA8] text-white">
                    <th className="py-2 px-4 border-r border-white">Model</th>
                    <th className="py-2 px-4 border-r border-white">
                      No. of Machines
                    </th>
                    <th className="py-2 px-4 border-r border-white">
                      Color Printing
                    </th>
                    <th className="py-2 px-4">Size</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedChoice.map((machine, index) => (
                    <tr className="bg-gray-200" key={index}>
                      <td className="py-2 px-4 border-r border-white">
                        {machine.name || "-"}
                      </td>
                      <td className="py-2 px-4 border-r border-white text-center">
                        {machine.machinenum || "-"}
                      </td>
                      <td className="py-2 px-4 border-r border-white text-center">
                        {machine.color || "-"}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {machine.size || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 py-10">
            {selectedChoice.map((machine, index) => (
              <div
                className="relative rounded-xl w-full h-[40vh]"
                key={index}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={`${machine.images}`}
                  alt={`Machine ${index + 1}`}
                  className="rounded-xl w-full h-full object-cover"
                />
                <span className="w-full absolute bottom-0 text-[#ffffff] py-3 flex justify-center items-center bg-[#383737]/50 rounded-b-xl">
                  {machine.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isOverlayOpen && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div
            className="fixed inset-0 bg-black bg-opacity-75"
            onClick={handleCloseOverlay}
          />
          <button
            className="absolute top-5 right-5 text-white text-5xl"
            onClick={handleCloseOverlay}
          >
            &times;
          </button>
          <div className="relative bg-white w-[80%] rounded-lg lg:p-5 flex flex-col items-center">
            <img
              src={`${selectedChoice[currentIndex].images}`}
              alt={`Machine ${currentIndex + 1}`}
              className="rounded-xl w-full max-h-[80vh] object-contain"
            />
            <p className="text-lg text-center px-2 pb-2 font-medium mt-4">
              {selectedChoice[currentIndex].name}
            </p>
          </div>
          <button
            className="text-gray-800 bg-white lg:text-3xl text-xl py-2 px-4 rounded-lg absolute top-[43vh] lg:left-16 left-3"
            onClick={handlePrevious}
          >
            <AiOutlineArrowLeft />
          </button>
          <button
            className="text-gray-800 bg-white lg:text-3xl text-xl py-2 px-4 rounded-lg absolute top-[43vh] lg:right-16 right-3"
            onClick={handleNext}
          >
            <AiOutlineArrowRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default IndividualProcess;
