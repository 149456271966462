import React, { useState } from "react";
import Slider from "react-slick";

const AboutUsHistory = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleToggleText = () => setShowFullText(!showFullText);

  const text = `The story of Nepal's printing press is closely tied to the visionary Mr. Amrit Man Shakya, who brought the printed word to life in the country. At a time when knowledge was primarily passed down orally or through handwritten manuscripts, his efforts to establish a printing press laid the groundwork for literacy, education, and cultural preservation. His contributions played a pivotal role in the spread of printing, particularly through the "New Printing Press," established in 1965 A.D. \n\nDuring this period, Nepal was largely isolated with limited access to printing technology. Mr. Amrit Man Shakya recognized the power of the printing press and the urgent need for a medium that could document and disseminate information widely. Through his press, Shakya began producing printed books, pamphlets, brochures, and posters, enabling not only the documentation of literature and history but also creating marketing opportunities for local businesses. \n\nShakya's efforts transformed Nepal's printing landscape. In 1988 AD, Sewa Printing Press emerged with the vision of elevating the legacy of the printing press to new heights using the latest technology of the time. Today, Sewa Printing Press is the preferred choice for anyone seeking top-notch output for all kinds of printing. Its state-of-the-art technology provides international standard quality to its customers. \n\nMr. Amrit Man Shakya's contributions to the printing press are celebrated as a defining moment in Nepal's printing history, underscoring the power of technology and innovation.`;

  const displayedText = showFullText ? text : `${text.slice(0, 500)}...`;

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,

    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    customPaging: function (i) {
      return <div className="dot w-[24px] h-[6px] bg-[#FFCD12]"></div>;
    },
    appendDots: (dots) => (
      <div>
        <ul className="flex justify-center space-x-4">{dots}</ul>
      </div>
    ),
  };

  return (
    <div className="bg-[#FEF6E5] w-full min-h-screen border-y-2 border-[#FFCD12] flex justify-center items-center">
      <div className="max-w-screen-2xl w-full mx-auto px-4 py-10 grid grid-cols-1 lg:grid-cols-2 xl:gap-24 lg:gap-18 gap-8 ">
        {/* Text Section */}
        <div className="flex flex-col justify-center space-y-8">
          <div className="flex items-center justify-start">
            <div className="bg-[#FFCD12] w-2 h-12 lg:h-16 mr-4"></div>
            <span className="font-semibold text-xl lg:text-2xl">
              The Man with the Vision!
            </span>
          </div>
          <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
            Mr. Amrit Man Shakya
          </h1>
          <p className="md:text-xl leading-relaxed whitespace-pre-wrap text-justify">
            {displayedText}
            <span
              onClick={handleToggleText}
              className="text-blue-600 cursor-pointer ml-2"
            >
              {showFullText ? "Show Less" : "Learn More"}
            </span>
          </p>
        </div>

        {/* Image Slider Section */}
        {/* <div className="flex justify-center items-center"> */}
        <Slider
          className="flex justify-center items-center about-gpa"
          {...settings}
        >
          <img
            src={require("../../assets/images/grandfather1.jpg")}
            alt="Grandfather"
            className="object-cover object-left h-full rounded-xl shadow-lg"
          />
          <img
            src={require("../../assets/images/grandfather2.jpeg")}
            alt="Grandfather"
            className="object-cover object-left h-full rounded-xl shadow-lg"
          />
        </Slider>
      </div>
    </div>
  );
};

export default AboutUsHistory;
