import React from "react";

const AboutUsDirector = () => {
  return (
    <div className="w-full py-5 border-b-2 border-[#FFCD12] flex justify-center items-center">
      <div className="max-w-screen-2xl w-full mx-auto px-4 py-10 grid grid-cols-1 lg:grid-cols-2 xl:gap-24 lg:gap-18 gap-8 ">
        {/* Image Slider Section */}
        <div className="flex justify-center items-center">
          <img
            src={require("../../assets/images/executive.jpeg")}
            alt="Grandfather"
            className="object-cover object-left w-full h-full rounded-xl shadow-lg"
          />
        </div>
        {/* Text Section */}
        <div className="flex flex-col justify-center space-y-8">
          <div className="flex items-center justify-start">
            <div className="bg-[#FFCD12] w-2 h-12 lg:h-16 mr-4"></div>
            <span className="font-semibold text-xl lg:text-2xl">
              Executive Director
            </span>
          </div>
          <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
            Mr. Amar Shakya
          </h1>
          <p className="md:text-xl leading-relaxed whitespace-pre-wrap text-justify">
            At Sewa, we believe that printing is not just a service but a craft
            that demands precision, creativity, and reliability. Whether it’s a
            large-scale corporate project or a personalized creative solution,
            our approach remains the same: to deliver excellence at every step.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsDirector;
