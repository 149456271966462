import React from "react";
import AboutUsWork1 from "../../assets/video/aboutUsWork1.mp4";
import AboutUsWork2 from "../../assets/video/aboutUsWork2.mp4";
import AboutUsWork3 from "../../assets/video/aboutUsWork3.mp4";

const AboutUsWork = () => {
  return (
    <div className="md:max-h-screen w-full max-w-screen-2xl mx-auto py-10 px-4 md:px-10 text-center ">
      <h1 className="font-bold text-[32px] md:text-[48px] leading-tight">
        Team Working
      </h1>
      <p className="md:text-xl mt-4 lg:mt-6 leading-relaxed text-justify mx-auto max-w-5xl">
        The Sewa Printing Press team works collaboratively, blending expertise
        in media relations, content creation, and strategic communication. They
        prioritize clear messaging and consistent outreach to maximize
        engagement and support for Sewa's initiatives.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-6">
        <div className="w-full h-[30vh] md:h-[50vh]">
          <video
            src={AboutUsWork1}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover rounded-2xl"
          ></video>
        </div>
        <div className="w-full h-[30vh] md:h-[50vh]">
          <video
            src={AboutUsWork2}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover rounded-2xl"
          ></video>
        </div>
        <div className="w-full h-[30vh] md:h-[50vh]">
          <video
            src={AboutUsWork3}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover rounded-2xl"
          ></video>
        </div>
      </div>
    </div>
  );
};

export default AboutUsWork;
