import React from "react";
import AboutUsLanding from "../components/aboutUs/aboutUsLanding";
import AboutUsHistory from "../components/aboutUs/aboutUsHistory";
import AboutUsTeam from "../components/aboutUs/aboutUsTeam";
import AboutUsWork from "../components/aboutUs/aboutUsWork";
import AboutUsDirector from "../components/aboutUs/aboutUsDirector";

const AboutUs = () => {
  return (
    <div className="bg-[#FEF8F0] font-poppins">
      <AboutUsLanding />
      <AboutUsHistory />
      <AboutUsDirector />
      <AboutUsTeam />
      <AboutUsWork />
    </div>
  );
};

export default AboutUs;
