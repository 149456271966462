import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "../components/general/nav.js";
import Footer from "../components/general/footer.js";
import Samples from "../pages/samples.js";
import Home from "../pages/home.js";
import Services from "../pages/services.js";
import ContactUs from "../pages/contactUs.js";
import AboutUs from "../pages/aboutUs.js";
import IndividualProcess from "../components/services/individualProcess.js";
import NotFound from "../pages/notFound.js";
import Clients from "../pages/clients.js";
import ToTop from "./scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetAQuote from "../components/popUps/GetAQuote.js";
import { Helmet } from "react-helmet";

const MainLayout = ({ children, isOpen, setIsOpen }) => {
  return (
    <>
      <Navbar />
      <div className="pt-20">{children}</div>
      <Footer />
    </>
  );
};
const routing = () => {
  return (
    <Router>
      <ToastContainer />
      <ToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Established in 1988, Sewa Printing Press combines four decades of expertise to cater to diverse printing needs for national and international agencies, corporate houses, NGOs, government organizations, and advertising agencies. Delivering innovative and high-quality printing solutions."
                />
                <meta
                  name="keywords"
                  content="Sewa Printing Press, printing solutions, printing services, printing in Nepal, corporate printing, NGO printing, government printing, advertising printing, high-quality printing, innovative printing"
                />
              </Helmet>
              <Home />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/services"
          element={
            <MainLayout>
              <Helmet>
                <title>Our Services | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Discover our comprehensive range of printing services, including Pre-Press, Printing, Post-Press, and Print-On-Demand. We provide high-quality solutions tailored to your needs."
                />
                <meta
                  name="keywords"
                  content="printing services, pre-press, post-press, print-on-demand, Sewa Printing Press, professional printing, Nepal printing solutions"
                />
              </Helmet>
              <Services />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/quote"
          element={
            <MainLayout>
              <GetAQuote />
            </MainLayout>
          }
        />
        <Route
          exact
          path="services/samples"
          element={
            <MainLayout>
              <Helmet>
                <title>Samples | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Explore our portfolio of printed materials, showcasing the quality and versatility of our services. From brochures to books, view samples of our craftsmanship."
                />
                <meta
                  name="keywords"
                  content="printing samples, portfolio, brochures, books, Sewa Printing Press, quality printing, sample work"
                />
              </Helmet>

              <Samples />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/contactUs"
          element={
            <MainLayout>
              <Helmet>
                <title>Contact Us | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Get in touch with Sewa Printing Press for inquiries, quotes, and assistance. Reach out to us via phone, email, or visit our office for personalized service."
                />
                <meta
                  name="keywords"
                  content="contact Sewa Printing Press, inquiries, printing quotes, contact details, customer support, printing assistance"
                />
              </Helmet>

              <ContactUs />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/aboutUs"
          element={
            <MainLayout>
              <Helmet>
                <title>About Us | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Learn more about Sewa Printing Press, established in 1988, a leader in the printing industry. Built on decades of expertise and innovation, we deliver exceptional printing services."
                />
                <meta
                  name="keywords"
                  content="about Sewa Printing Press, printing history, established 1988, printing expertise, printing innovation, Nepal printing"
                />
              </Helmet>

              <AboutUs />
            </MainLayout>
          }
        />

        <Route
          exact
          path="services/:process"
          element={
            <MainLayout>
              <IndividualProcess />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/clients"
          element={
            <MainLayout>
              <Helmet>
                <title>Our Clients | Sewa Printing Press</title>
                <meta
                  name="description"
                  content="Meet our esteemed clients, including national and international agencies, corporate houses, NGOs, government organizations, and advertising agencies. Trusted by leaders in every field."
                />
                <meta
                  name="keywords"
                  content="Sewa Printing Press clients, corporate clients, NGO clients, government clients, trusted printing partner"
                />
              </Helmet>

              <Clients />
            </MainLayout>
          }
        />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default routing;
